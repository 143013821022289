<template>
  <div>
    <Heading
      title="Services"
      subtitle=" Elevating Players to Their Pinnacle of Success through Expert Guidance and Strategic Management"
    ></Heading>
    <section class="textComponent textComponent--left">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Player Representation</h2>
        <p class="textComponent__description">
          At our football agency, we specialize in providing unparalleled player
          representation services. Our dedicated team of experienced agents is
          committed to safeguarding the interests of football players during
          every step of their careers. Through expert negotiation skills,
          strategic planning, and personalized attention, we ensure that our
          clients' unique goals and aspirations are not only protected but also
          maximized for success.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--right">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Contract Nagotiation</h2>
        <p class="textComponent__description">
          Our agency excels in the art of contract negotiation. With a deep
          understanding of the football industry and an extensive network of
          contacts, we secure contracts that not only meet but often exceed our
          clients' expectations. We meticulously review terms and conditions to
          ensure our players receive the compensation, benefits, and
          opportunities they rightfully deserve.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--left">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Strategic Career Planning</h2>
        <p class="textComponent__description">
          Beyond contract negotiations, we offer comprehensive strategic career
          planning services. We take a holistic approach, actively managing all
          facets of our clients' careers. From selecting the most suitable clubs
          and teams to navigating transfers and loan deals, we guide our players
          toward long-term success, helping them achieve their career
          objectives.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--right">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Club Partnerships</h2>
        <p class="textComponent__description">
          Our agency leverages its connections and expertise to establish
          valuable club partnerships. These partnerships benefit both players
          and organizations, ensuring that players are aligned with clubs that
          match their ambitions and talents. We foster mutually beneficial
          relationships that contribute to our clients' growth and success.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--left">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Brand Endorsements</h2>
        <p class="textComponent__description">
          Unlocking the off-field earning potential of our clients, we
          specialize in securing brand endorsements and sponsorship deals. By
          carefully matching players with brands that resonate with their image
          and values, we create lasting partnerships that extend beyond the
          pitch.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--right">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Legal and Financial Advisory</h2>
        <p class="textComponent__description">
          To provide comprehensive support, our agency offers legal and
          financial advisory services. Our experts navigate complex legal and
          financial matters while ensuring compliance with FIFA and national
          governing body guidelines. This meticulous attention to detail
          guarantees that our clients are well-informed and protected in all
          aspects of their careers.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--left">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Scouting and Recruitment</h2>
        <p class="textComponent__description">
          With a keen eye for talent, we identify emerging prospects and
          opportunities in the football world. Our scouting services are
          designed to keep players and clubs ahead of the competition by
          recognizing and capitalizing on promising talent.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--right">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Digital Presence Management</h2>
        <p class="textComponent__description">
          In today's digital landscape, maintaining a strong online presence is
          vital. We take charge of managing and enhancing our clients' digital
          presence across various platforms. Through engaging content, fan
          interaction, and strategic online strategies, we help players build
          and nurture their fan base.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--left textComponent--last">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Educational Programs</h2>
        <p class="textComponent__description">
          To foster well-rounded development, we offer educational programs that
          empower our clients with industry knowledge and essential skills.
          These programs enable players to make informed decisions about their
          careers and equip them for success both on and off the field.
        </p>
      </div>
    </section>
    <Counter
      title="Achievements in numbers"
      description="Our agency boasts an impressive roster of registered players, representing a diverse array of talent. Moreover, we take pride in having contributed to the world of football, with a notable presence in the last two FIFA World Cups. Additionally, we have facilitated the careers of numerous players who now shine in the top five football leagues around the globe. This is a testament to our commitment to nurturing and promoting football excellence on a global scale."
      :maxCounter1="123"
      :maxCounter2="16"
      :maxCounter3="79"
      text1="Registreted players"
      text2="Players on last 2 World Cups"
      text3="Players in TOP Leagues"
    ></Counter>
  </div>
</template>

<script>
import Heading from "../components/Heading.vue";
import Counter from "../components/Counter.vue";

export default {
  components: {
    Heading,
    Counter,
  },
};
</script>
