<template>
  <div>
    <NewsBanner></NewsBanner>
    <Swiper></Swiper>
    <section class="textComponent">
      <h2 class="textComponent__title">About Us</h2>
      <p class="textComponent__description">
        At VPA Sports, we stand as a collaborative alliance, uniting a
        consortium of reputable sports agencies and seasoned representatives.
        Together, we bring forth a wealth of knowledge and expertise to empower
        our clients within the dynamic landscape of sports and football
        management. <br />As a collective force, we navigate the ever-evolving
        industry, ensuring our clients receive unparalleled support and
        strategic guidance.
      </p>
    </section>
    <section class="textComponent">
      <h2 class="textComponent__title">Services</h2>
      <p class="textComponent__description">
        Explore the pinnacle of football management with VPA Sports. Our curated
        suite of services is designed to elevate every facet of our clients'
        careers, offering expert representation, strategic planning, and a range
        of specialized solutions. <br />Discover how our comprehensive approach
        sets a new standard in the world of football management.
      </p>
    </section>
    <section class="curvedContainerWrapper">
      <div class="curvedContainer">
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Player <br />
            Representation
          </h2>
        </div>
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Expert representation and management for football players, ensuring
            their interests are safeguarded in negotiations, contracts, and
            career decisions.
          </p>
        </div>
      </div>
      <div class="curvedContainer curvedContainer--reverse">
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Skillful negotiation of player contracts, endorsements, and other
            agreements to secure favorable terms and maximize opportunities
          </p>
        </div>
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Contract <br />
            Negotiation
          </h2>
        </div>
      </div>
      <div class="curvedContainer">
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Strategic Career <br />
            Planning
          </h2>
        </div>
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Tailored guidance on career trajectories, helping players make
            informed decisions to achieve long-term success in the football
            industry.
          </p>
        </div>
      </div>
      <div class="curvedContainer curvedContainer--reverse">
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Facilitation of partnerships between players and football clubs,
            leveraging extensive networks for mutually beneficial relationships.
          </p>
        </div>
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Club <br />
            Partnerships
          </h2>
        </div>
      </div>
      <div class="curvedContainer">
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Brand <br />
            Endorsements
          </h2>
        </div>
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Exploration and negotiation of brand endorsement deals to enhance
            players' visibility and marketability.
          </p>
        </div>
      </div>
      <div class="curvedContainer curvedContainer--reverse">
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Provision of legal and financial counsel to ensure compliance with
            regulations and sound financial management for players.
          </p>
        </div>
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Legal and Financial <br />
            Advisory
          </h2>
        </div>
      </div>
      <div class="curvedContainer">
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Scouting and <br />
            Recruitment
          </h2>
        </div>
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Identification and recruitment of talented football players, working
            closely with clubs to strengthen their teams.
          </p>
        </div>
      </div>
      <div class="curvedContainer curvedContainer--reverse">
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Strategic management of players' digital presence, including social
            media and online branding, to build a strong and positive public
            image.
          </p>
        </div>
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Digital Presence <br />
            Management
          </h2>
        </div>
      </div>
      <div class="curvedContainer">
        <div class="curvedContainer__main">
          <h2 class="curvedContainer__heading">
            Educational <br />
            Programs
          </h2>
        </div>
        <div class="curvedContainer__description">
          <img
            src="../../assets/images/background/heroesBg.jpeg"
            alt="Background image"
          />
          <p class="curvedContainer__text">
            Implementation of educational initiatives to empower players with
            essential skills beyond the field, fostering personal and
            professional development.
          </p>
        </div>
      </div>
    </section>
    <Counter
      title="Achievements in numbers"
      description="Our agency boasts an impressive roster of registered players, representing a diverse array of talent. Moreover, we take pride in having contributed to the world of football, with a notable presence in the last two FIFA World Cups. Additionally, we have facilitated the careers of numerous players who now shine in the top five football leagues around the globe. This is a testament to our commitment to nurturing and promoting football excellence on a global scale."
      :maxCounter1="123"
      :maxCounter2="16"
      :maxCounter3="79"
      text1="Registreted players"
      text2="Players on last 2 World Cups"
      text3="Players in TOP Leagues"
    ></Counter>
  </div>
</template>

<script>
import Swiper from "@/vue/components/Swiper";
import NewsBanner from "@/vue/components/NewsBanner";
import Counter from "../components/Counter.vue";

export default {
  components: {
    Swiper,
    NewsBanner,
    Counter,
  },
};
</script>
