<template>
  <div class="newsBanner">
    <div class="newsBanner__content">
      <p class="newsBanner__text">Check us on Instagram and Transfermarkt</p>
      <a
        class="newsBanner__logo"
        href="https://www.instagram.com/vpa.sports/"
        target="”_blank”"
      >
        <img
          src="@/assets/images/social/instagram_logo.png"
          alt="Instagram Logo"
        />
      </a>
      <a
        class="newsBanner__logo"
        href="https://www.transfermarkt.com/vpa-sports-international-ltd-/beraterfirma/berater/7867"
        target="”_blank”"
      >
        <img
          src="@/assets/images/social/transferMark_logo.png"
          alt="Instagram Logo"
        />
      </a>
    </div>
  </div>
</template>
