<template>
  <div>
    <header class="header-desktop">
      <router-link to="/Home">
        <div class="header-desktop__logo">
          <img
            class="header-desktop__logo-image"
            src="../../assets/images/logo/vpa-logo.png"
            alt=""
          />
        </div>
      </router-link>

      <nav class="header-desktop__navigation">
        <ul class="header-desktop__navigation-list">
          <li class="header-desktop__navigation-list-item">
            <router-link to="/Home">Home</router-link>
          </li>
          <li class="header-desktop__navigation-list-item">
            <router-link to="/Services">Services</router-link>
          </li>
          <li class="header-desktop__navigation-list-item">
            <router-link to="/About">About Us</router-link>
          </li>
          <li
            class="header-desktop__navigation-list-item header-desktop__navigation-list-item--last"
          >
            <router-link to="/Contact">Contact</router-link>
          </li>
        </ul>
      </nav>
    </header>

    <header class="header-mobile">
      <router-link to="/Home">
        <div class="header-mobile__logo">
          <img
            class="header-mobile__logo-image"
            src="../../assets/images/logo/vpa-logo.png"
            alt=""
          />
        </div>
      </router-link>

      <button class="header-mobile__button" @click="toggleNavigation()">
        {{ menuText }}
      </button>
      <div class="menu header-mobile__menu" :class="{ show: isOpened }">
        <nav class="header-mobile__navigation">
          <ul class="header-mobile__navigation-list">
            <li class="header-mobile__navigation-list-item">
              <router-link @click="toggleNavigation()" to="/Home"
                >Home</router-link
              >
            </li>
            <li class="header-mobile__navigation-list-item">
              <router-link @click="toggleNavigation()" to="/Services"
                >Services</router-link
              >
            </li>
            <li class="header-mobile__navigation-list-item">
              <router-link @click="toggleNavigation()" to="/About"
                >About Us</router-link
              >
            </li>
            <li class="header-mobile__navigation-list-item">
              <router-link @click="toggleNavigation()" to="/Contact"
                >Contact</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpened: false,
      menuText: "Menu",
    };
  },

  methods: {
    toggleNavigation() {
      this.isOpened = !this.isOpened;

      if (this.isOpened) {
        document.body.classList.add("lock");
        this.menuText = "Close";
      }
      if (!this.isOpened) {
        document.body.classList.remove("lock");
        this.menuText = "Menu";
      }
    },
  },
};
</script>
