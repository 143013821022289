<template>
  <section class="contact-page">
    <Heading
      title="Contact"
      subtitle="Connecting Players with Exceptional Opportunities – Reach Out to Us Today"
    ></Heading>
    <section class="textComponent textComponent--right">
      <div class="textComponent__wrapper">
        <p class="textComponent__description">
          Please provide us with additional details regarding your request so
          that we can ensure it reaches the appropriate recipient.
          Alternatively, you can reach us directly by emailing
          office@vpasports.international.
        </p>
      </div>
    </section>

    <form class="contact" ref="form" @submit.prevent="sendEmail">
      <div class="contact__wrapper">
        <div class="form-group">
          <label>Name and Surname</label>
          <input type="text" name="user_name" required />
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="email" name="user_email" required />
        </div>
        <div class="form-group">
          <label>Message</label>
          <textarea name="message" rows="8" required></textarea>
        </div>
        <input class="button" type="submit" value="Send" />
        <div class="contact-alert">
          <p v-if="throwSucces == true" class="contact-alert__message">
          Thank you for reaching out to us through the website form. Our team will respond to your message as promptly as possible.
          </p>
          <p v-if="throwSucces == false"  class="contact-alert__message contact-alert__message--error">
            Something went wrong, please try later !
          </p>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Heading from "../components/Heading.vue";
import emailjs from "emailjs-com";

export default {
  components: {
    Heading,
  },

  data() {
    return {
      throwSucces: null,
      service: "service_120i6ai",
      template: "template_r2fsscp",
      publicKey: "FduthNGq3D83QS84J",
    };
  },

  methods: {
    sendEmail() {
      emailjs
        .sendForm(this.service, this.template, this.$refs.form, this.publicKey)
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.$refs.form.reset();
            this.throwSucces = true;
          },
          (error) => {
            console.log("FAILED...", error.text);
              this.$refs.form.reset();
             this.throwSucces = false;
          }
        );
    },
  },
};
</script>
