<template>
  <section>
    <section class="textComponent">
      <h2 class="textComponent__title">{{ title }}</h2>
      <p class="textComponent__description">
        {{ description }}
      </p>
    </section>
    <section class="counter">
      <div class="counter__wrapper">
        <p class="counter__value counter__value-1">{{ value1 }}</p>
        <h3 class="counter__text counter__text-1">
          {{ text1 }}
        </h3>
      </div>

      <div class="counter__wrapper">
        <p class="counter__value counter__value-2">{{ value2 }}</p>
        <h3 class="counter__text counter__text-2">
          {{ text2 }}
        </h3>
      </div>

      <div class="counter__wrapper">
        <p class="counter__value counter__value-3">{{ value3 }}</p>
        <h3 class="counter__text counter__text-3">{{ text3 }}</h3>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    maxCounter1: {
      type: Number,
      required: true,
    },
    maxCounter2: {
      type: Number,
      required: true,
    },
    maxCounter3: {
      type: Number,
      required: true,
    },
    text1: {
      type: String,
      required: true,
    },
    text2: {
      type: String,
      required: true,
    },
    text3: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      intervalTime: 60,
      resetDelay: 5000,
      value1: 0,
      value2: 0,
      value3: 0,
    };
  },

  mounted() {
    this.valueCounter1();
    this.valueCounter2();
    this.valueCounter3();
  },

  methods: {
    valueCounter1() {
      const updateCounter1 = () => {
        this.value1++;
        if (this.value1 >= this.maxCounter1) {
          clearInterval(counterInterval1); // Clear the interval
          setTimeout(() => {
            this.value1 = 0;
            this.valueCounter1(); // Start counting again after the delay
          }, this.resetDelay);
        }
      };

      const counterInterval1 = setInterval(updateCounter1, this.intervalTime);
    },

    valueCounter2() {
      const updateCounter2 = () => {
        this.value2++;
        if (this.value2 >= this.maxCounter2) {
          clearInterval(counterInterval2); // Clear the interval
          setTimeout(() => {
            this.value2 = 0;
            this.valueCounter2(); // Start counting again after the delay
          }, this.resetDelay);
        }
      };

      const counterInterval2 = setInterval(updateCounter2, this.intervalTime);
    },

    valueCounter3() {
      const updateCounter3 = () => {
        this.value3++;
        if (this.value3 >= this.maxCounter3) {
          clearInterval(counterInterval3); // Clear the interval
          setTimeout(() => {
            this.value3 = 0;
            this.valueCounter3(); // Start counting again after the delay
          }, this.resetDelay);
        }
      };

      const counterInterval3 = setInterval(updateCounter3, this.intervalTime);
    },
  },
};
</script>
