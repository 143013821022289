import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '../vue/pages/HomePage';
import Services from '../vue/pages/Services';
import About from '../vue/pages/About';
import Contact from '../vue/pages/Contact';
import NotFound from '../vue/pages/NotFound';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/Home' },
    { path: '/Home', component: HomePage },
    { path: '/Services', component: Services },
    { path: '/About', component: About },
    { path: '/Contact', component: Contact },
    { path: '/:notFound(.*)', component: NotFound }
  ]
});

export default router;