import { createApp } from 'vue';
import App from '@/vue/App.vue';

//Swiper styles import
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '@/styles/main.scss';
import router from './scripts/router';

const app = createApp(App);
app.use(router);
app.mount('#app');
