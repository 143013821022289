<template>
  <section class="about-page">
    <Heading
      title="About us"
      subtitle="Explore our vision and commitment to players' success"
    ></Heading>

    <section class="textComponent">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Global presence</h2>
        <p class="textComponent__description">
          Welcome to VPA SPORTS International, your global football destination
          with offices in London, Dubai, Zagreb, Belgrade, Zurich, Brussels, and
          Warsaw. Our team offers player representation, contract negotiations,
          career planning, endorsements, legal and financial advice, scouting,
          and more to help you achieve your football dreams worldwide.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--left">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Vision</h2>
        <p class="textComponent__subTitle">
          "Empowering Players, Elevating Dreams"
        </p>
        <p class="textComponent__description">
          Our vision is to be the premier player agency dedicated to empowering
          athletes on their journey to success. We envision a future where every
          player we represent achieves their highest potential, both on and off
          the field, and where our agency is synonymous with excellence,
          integrity, and unwavering support.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--right">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Mission</h2>
        <p class="textComponent__subTitle">
          "Guiding Players to Excellence Through Strategic Representation"
        </p>
        <p class="textComponent__description">
          Our mission is to provide comprehensive and strategic representation
          to players, fostering an environment where talent meets opportunity.
          We are committed to navigating the complex landscape of professional
          sports, offering personalized guidance, and creating partnerships that
          propel players toward their career aspirations. Through unwavering
          advocacy and a player-centric approach, we aim to be the catalyst for
          our clients' sustained success.
        </p>
      </div>
    </section>
    <section class="textComponent textComponent--left textComponent--last">
      <div class="textComponent__wrapper">
        <h2 class="textComponent__title">Goals</h2>
        <p class="textComponent__subTitle">
          "Aspirations for Success: Driving comprehensive player development,
          fostering global partnerships, and delivering innovative services in
          alignment with our vision and mission of 'Empowering Players,
          Elevating Dreams.'"
        </p>
        <p class="textComponent__description">
          At our core, we prioritize the holistic development of players and
          foster strategic partnerships worldwide. With a commitment to global
          reach and innovation, we aim to connect players with diverse
          opportunities, embracing cutting-edge services. Our goal extends
          beyond sports, actively contributing to communities worldwide.
          Upholding the highest ethical standards, we stand as strong advocates
          for players' rights and well-being in the sports industry.
        </p>
      </div>
    </section>
    <Counter
      title="Achievements in numbers"
      description="At VPA SPORTS International, our global network of offices, including locations in London, Dubai, Zagreb, Belgrade, Zurich, Brussels, and Warsaw, empowers us to provide world-class football representation. These offices serve as hubs for our expert team, enabling us to serve clients worldwide. We pride ourselves on strong partnerships with renowned football entities, and our track record of successfully completed contracts globally highlights our commitment to securing top opportunities for our clients."
      :maxCounter1="7"
      :maxCounter2="39"
      :maxCounter3="381"
      text1="Offices"
      text2="Club Partnerships"
      text3="Contracts Successfully Negotiated"
    ></Counter>
  </section>
</template>

<script>
import Heading from "../components/Heading.vue";
import Counter from "../components/Counter.vue";

export default {
  components: {
    Heading,
    Counter,
  },
};
</script>
