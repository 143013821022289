<template>
  <Header></Header>
  <router-view></router-view>
  <Footer></Footer>
</template>

<script>
import Header from "@/vue/components/Header";
import Footer from "@/vue/components/Footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
