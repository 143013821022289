<template>
  <footer class="footer">
    <div class="footer__contact">
      <div class="footer__contact-content">
        <router-link class="footer__contact-text" to="/Contact">Contact Us</router-link>
      </div>
    </div>
    <div class="footer__main">
      <h3 class="footer__main-quote">The agency for chosen ones</h3>
      <div class="footer__container">
        <nav class="footer__main-navigation">
          <ul class="footer__main-navigation-list">
            <li class="footer__main-navigation-list-item">
              <router-link to="/Home">Home</router-link>
            </li>
            <li class="footer__main-navigation-list-item">
              <router-link to="/Services">Services</router-link>
            </li>
            <li class="footer__main-navigation-list-item">
              <router-link to="/About">About us</router-link>
            </li>
            <li class="footer__main-navigation-list-item">
              <router-link to="/Contact">Contact</router-link>
            </li>
          </ul>
        </nav>
        <p class="footer__copyRight">
          © Copyright 2023 | All Rights Reserved | Powered by VPA SPORTS
          International
        </p>
      </div>

      <div class="footer__main-social">
        <p class="footer__main-social-qoute">Check us on Social Media</p>
        <div class="footer__main-social-pages">
          <a class="footer__main-social-link" target="blank" href="https://www.instagram.com/vpa.sports/"> Instagram
          </a>
          <a class="footer__main-social-link" target="blank"
            href="https://www.transfermarkt.com/vpa-sports-international-ltd-/beraterfirma/berater/7867"
          > Transfermarkt
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
