<template>
  <div class="heading">
    <div class="heading__content">
      <h1 class="heading__title"> {{ title }}</h1>
      <p class="heading__subtitle">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String
  },
};
</script>
