<template>
  <swiper
    :spaceBetween="1"
    :centeredSlides="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :navigation="true"
    :modules="modules"
    class="custom-swiper"
  >
    <swiper-slide>
      <div class="custom-swiper__content">
        <h2 class="custom-swiper__heading">London</h2>
        <p class="custom-swiper__quote">Home of Iconic Football Clubs</p>
      </div>
      <picture>
        <source
          media="(min-width:1100px)"
          srcset="../../assets/images/swiper/london/london3x.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../../assets/images/swiper/london/london2x.jpg"
        />
        <img
          class="custom-swiper__image"
          src="../../assets/images/swiper/london/london1x.jpg"
          alt="London Image"
        />
      </picture>
    </swiper-slide>
    <swiper-slide>
      <div class="custom-swiper__content">
        <h2 class="custom-swiper__heading">Zürich</h2>
        <p class="custom-swiper__quote">Swiss Precision, Football Passion</p>
      </div>
      <picture>
        <source
          media="(min-width:1100px)"
          srcset="../../assets/images/swiper/zug/zug3x.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../../assets/images/swiper/zug/zug2x.jpg"
        />
        <img
          class="custom-swiper__image"
          src="../../assets/images/swiper/zug/zug1x.jpg"
          alt="Zureich Image"
        />
      </picture>
    </swiper-slide>
    <swiper-slide>
      <div class="custom-swiper__content">
        <h2 class="custom-swiper__heading">Dubai</h2>
        <p class="custom-swiper__quote">Football in the Heart of the Desert</p>
      </div>
      <picture>
        <source
          media="(min-width:1100px)"
          srcset="../../assets/images/swiper/dubai/dubai3x.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../../assets/images/swiper/dubai/dubai2x.jpg"
        />
        <img
          class="custom-swiper__image"
          src="../../assets/images/swiper/dubai/dubai1x.jpg"
          alt="Dubai Image"
        />
      </picture>
    </swiper-slide>
    <swiper-slide>
      <div class="custom-swiper__content">
        <h2 class="custom-swiper__heading">Zagreb</h2>
        <p class="custom-swiper__quote">A Hub for Rising Football Stars</p>
      </div>
      <picture>
        <source
          media="(min-width:1100px)"
          srcset="../../assets/images/swiper/zagreb/zagreb3x.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../../assets/images/swiper/zagreb/zagreb2x.jpg"
        />
        <img
          class="custom-swiper__image"
          src="../../assets/images/swiper/zagreb/zagreb1x.jpg"
          alt="Zagreb Image"
        />
      </picture>
    </swiper-slide>

    <swiper-slide>
      <div class="custom-swiper__content">
        <h2 class="custom-swiper__heading">Belgrade</h2>
        <p class="custom-swiper__quote">Where Football is a Way of Life</p>
      </div>
      <picture>
        <source
          media="(min-width:1100px)"
          srcset="../../assets/images/swiper/belgrade/belgrade3x.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../../assets/images/swiper/belgrade/belgrade2x.jpg"
        />
        <img
          class="custom-swiper__image"
          src="../../assets/images/swiper/belgrade/belgrade1x.jpg"
          alt="Belgrade Image"
        />
      </picture>
    </swiper-slide>
    <swiper-slide>
      <div class="custom-swiper__content">
        <h2 class="custom-swiper__heading">Warsaw</h2>
        <p class="custom-swiper__quote">The Football Capital of Poland</p>
      </div>
      <picture>
        <source
          media="(min-width:1100px)"
          srcset="../../assets/images/swiper/warsaw/warsaw3x.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../../assets/images/swiper/warsaw/warsaw2x.jpg"
        />
        <img
          class="custom-swiper__image"
          src="../../assets/images/swiper/warsaw/warsaw1x.jpg"
          alt="Warsaw Image"
        />
      </picture>
    </swiper-slide>
    <swiper-slide>
      <div class="custom-swiper__content">
        <h2 class="custom-swiper__heading">Brusseles</h2>
        <p class="custom-swiper__quote">Football Fusion in the Heart of Europe</p>
      </div>
      <picture>
        <source
          media="(min-width:1100px)"
          srcset="../../assets/images/swiper/brus/brus3x.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../../assets/images/swiper/brus/brus2x.jpg"
        />
        <img
          class="custom-swiper__image"
          src="../../assets/images/swiper/brus/brus1x.jpg"
          alt="Brusseles Image"
        />
      </picture>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>
